<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <v-autocomplete
      v-model="value"
      :class="`dropdown-singleselect${classes}`"
      :disabled="disabled"
      :items="options"
      item-text="text"
      item-value="id"
      return-object
      autocomplete="on"
      ligth="true"
    >
      <template
        v-slot:selection="data"
      >
        <v-avatar
          v-if="validColor(data.item.color)"
          :color="data.item.color"
          size="12"
          class="mr-2 elevation-1"
        />
        {{ data.item.text }}
      </template>
      <template
        v-slot:item="data"
      >
        <v-list-item-avatar
          v-if="validColor(data.item.color)"
          :color="data.item.color"
          size="12"
          class="mr-2 elevation-1"
        />
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeDropdown',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabled() {
      return this.state.disabled
    },
    options() {
      const optionsOriginal = this.cell.settings.dropdown_builder || []
      const optionsSorting = parseInt(this.cell.settings.dropdown_sorting || 0) || 0
      const options = []
      if (!Array.isArray(optionsOriginal)) {
        return options
      }
      for (let i = 0, option; (option = optionsOriginal[i]); i++) {
        if (typeof option === 'string') {
          option = {
            value: option,
            color: ''
          }
        }
        option.id = i
        option.text = this.optionLabel(option)
        options.push(option)
      }
      if (optionsSorting === 1) {
        options.sort(function(a, b) {
          if (a.value > b.value) {
            return 1;
          }
          if (a.value < b.value) {
            return -1;
          }
          return 0;
        })
      }
      if (optionsSorting === 2) {
        options.sort(function(a, b) {
          if (a.value > b.value) {
            return -1;
          }
          if (a.value < b.value) {
            return 1;
          }
          return 0;
        })
      }
      return options
    },
    value: {
      get() {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let value = builder.getCellValue(index, this.cell, this.clone)
        try {
          if (typeof value === 'string') {
            value = JSON.parse(value)
          }
        } catch (ex) {
          value = []
        }
        value = parseInt(builder.getSelectPropertyToArray(value, 'id')[0])
        return {
          id: value || 0
        }
      },
      set(event) {
        const builder = this.data.ChecklistBuilder
        const index = this.checklist.index
        let value = []
        if (event.hasOwnProperty('id')) {
          value = [{
            id: event.id,
            label: event.text || ''
          }]
        }
        const previousValue = this.value
        builder.evalValues({
          index: index,
          dataEditor: this.cell,
          dataViewer: this.dataViewer,
          cloneIndex: this.clone,
          encode: false,
          saveCallback: undefined,
          ignoreAutoSaveSetting: false,
          value: value,
          previousValue: previousValue
        })
      }
    }
  },
  methods: {
    optionLabel(option) {
      const builder = this.data.ChecklistBuilder
      return builder.getLocalization(option, 'value') || ''
    },
    validColor(color) {
      if (!color) {
        return false
      }
      if (/transparent/i.test(color)) {
        return false
      }
      if (/rgba\(([0-9]+, ){3}0\)/i.test(color)) {
        return false
      }
      return true
    }
  }
}
</script>
